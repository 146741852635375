import React from "react";
import { Link } from "gatsby"
import Layout from "../../components/Layout";
import Section from "../../components/Section";
import VideoPlayerJS from "../../components/Player";
import SectionBanner from "../../components/SectionBanner";
import SectionOverview from "../../components/SectionOverview"
import Seo from "../../components/Seo";

export default function AboutLanding() {

  

  return (
    <Layout id="holiday-landing">
      <Seo
        title="Holiday 2023"
        index={false} f
        ollow={false}
        relPath={"/hoilday2023"}
      />
      <div className="page--about">

        <SectionBanner
          id={"about-hero"}
          bgImage={"assets/images/holiday-special.png"}
          bgTextShade={true}
          title="Bixal Holiday Special 2023"
        />

        

        <Section
          id="holiday-intro"
          constrain
          indent
          spacing={120}
        >
          <VideoPlayerJS name="https://d474nuifpska.cloudfront.net/holiday-special.m3u8" controls></VideoPlayerJS>
          <SectionOverview divider>
            <h2>Join us for this year's Holiday Special!</h2>
          </SectionOverview>

          <p>The 2023 Bixal Holiday Special is just around the corner! Save the date for Bixal’s 
          one of a kind holiday tradition, the Bixal Holiday Special, now in its fourth year. </p> 
 
          <p>The Holiday Special is a fun, live broadcast event created especially for you and your loved ones 
          to sit back and enjoy laugh-out-loud skits, dances, musical performances, cooking segments and warm 
          holiday wishes. More than 40 of your fellow Bixalers around the world are busy making this an event 
          you won’t want to miss.</p>

          <p>This year’s live Special takes place on Wednesday, December 6, 2023, at 6 p.m. EST (23:00 UTC). And 
          again, this year, we’ve added a live rebroadcast for those worldwide. It will air on Thursday, 
          December 7 at 8 a.m. EST (13:00 UTC). </p>

          <p>Visit this page at the time of the event and be sure to join the Teams meeting on your calendar to participate in the live Holiday Special banter.
          </p>

        </Section>

      </div>
    </Layout>
  )
}
